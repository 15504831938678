import React from 'react';
import { Row, Col, Carousel, List, Typography, Card } from 'antd';

import './home.css';

const { Paragraph } = Typography;

export class Home extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Row gutter={16} style={{ marginTop: 25 }}>
                    <Col span={16}>
                        <Carousel autoplay>
                            <img src='/images/002.jpg' height={300} className='carousel-image' />
                            <img src='/images/003.jpg' height={300} className='carousel-image' />
                            <img src='/images/012.jpg' height={300} className='carousel-image' />
                        </Carousel>
                    </Col>
                    <Col span={8}>
                        <List
                            header={<h2>NEWS</h2>}
                            bordered
                            dataSource={data}
                            renderItem={item => (
                                <List.Item>
                                    <a href={item.hrefLink} target='_blank'>{item.title}</a>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
                <Paragraph style={{ marginTop: 50, fontSize: '1.25em' }}>
                    Fab Lanka is a group of highly motivated individuals coming together to set up fabrication labs (fab labs) throughout Sri Lanka as a community outreach project. The abundance of an educated work force, high degree of IT usage, and existence of a large number of small and medium size manufactures (SMEs) makes Sri Lanka an ideal home for fab labs. The benefits of bringing in new technology is the ability to transform the manufacturing sector of local economies in environmentally friendly ways. In addition, it will also strengthen employment for youth in the 21st century manufacturing job sector.
                </Paragraph>                
                <Paragraph style={{ marginTop: 50, fontSize: '1.25em' }}>
                    Inspired by the technologically based social movements such as Open Source Ecology, FabLab, and RepRap who’s aim is to spread technology and knowledge to build a more equitable society, FabLanka aspires to provide a platform for social and economic development through education and technological innovation.
                </Paragraph>
                <Row>
                    <Col span={16}>
                        <List
                            style={{ marginTop: 50 }}
                            header={<h2>Recent Events</h2>}
                            itemLayout="horizontal"
                            dataSource={recentEvents}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={<a style={{color: 'blue'}} href={item.hrefLink} target='_blank'>{item.title}</a>}
                                    />
                                    <div>{item.date}</div>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const data = [
    { title: 'Guest Talk on Systems Engineering', hrefLink: 'https://www.youtube.com/watch?v=KQ1oVc6jlgE&feature=youtu.be' },
    { title: 'Interview with Channel Eye on Rise & Shine', hrefLink: 'https://www.youtube.com/watch?time_continue=66&v=ifplGatdDPg' },
    { title: 'Student Interview', hrefLink: 'https://www.facebook.com/fablabmakandura/videos/1044097915725729/' },
    { title: 'Priyantha Palapathwala at Ada Derana Biz 24x7', hrefLink: 'https://www.youtube.com/watch?v=UgWG7tInPxs&t=394s' }
];

const recentEvents = [
    { title: 'Guest Talk At Colombo University', date: '27 Jan, 2017', hrefLink: 'https://www.youtube.com/watch?v=5OJHj2N6LGs' },
    { title: 'Session at Sri Jayawardhanapura', date: '24 Dec, 2015', hrefLink: 'https://www.facebook.com/FabLabSriLanka/videos/1525816177711299/?video_source=pages_finch_main_video' },
    { title: 'Session at Colombo MBA Course', date: '20 Dec, 2015', hrefLink: 'https://www.youtube.com/watch?v=0qGrPnhZNv8' },
    { title: 'FabLanka Variety Show', date: '14 Nov, 2015', hrefLink: 'https://www.youtube.com/watch?v=cKJPfUZZnkA&list=PLELAEHga4IGxgHiQp58x-rOkbLhG2vHlZ' },
    { title: 'At Wickramasheela National School', date: '27 Oct, 2015', hrefLink: 'https://www.youtube.com/watch?v=B2Og8wX_h4w' },
    { title: 'ICTD Presentation', date: '15 May, 2015', hrefLink: '/files/ICTDPanelDiscussionPresentation.pdf' },
    { title: 'Online Demonstration Of CAD/CAM', date: '05 Apr, 2015', hrefLink: 'https://www.youtube.com/watch?v=L5Yjssno2DI' },
    { title: 'First Public Outreach Event', date: '26 Oct, 2014', hrefLink: 'https://www.youtube.com/watch?v=LnKBioAuyuo' },
    { title: 'Setting Up a FabLab In Sri Lanka', date: '26 Jul, 2014', hrefLink: 'https://www.youtube.com/watch?v=FtXM9jQ_U0E' },
    { title: 'Talk On Digital Manufacturing', date: '17 May, 2014', hrefLink: 'https://www.youtube.com/watch?v=XDa6cdEyJUQ' }
]