import React from 'react';
import { Typography, Timeline, Col, Card } from 'antd';

const { Title, Paragraph } = Typography;

export class Plan extends React.Component {
    render() {
        return (
            <div style={{ marginTop: 50 }}>
                <Title level={3} style={{ marginTop: 25 }}>Our Plan</Title>
                <Paragraph style={{ fontSize: '1.25em', textAlign: 'justify' }}>
                    FabLanka’s goal is to establish a network of self-sufficient fabrication labs (FabLabs) successfully incorporating ideas across various multidisciplinary platforms. These FabLabs will serve as training centers and innovation centers for dispersion of existing and emerging technologies of the 4th industrial revolution and also as resource centers for new Product Development.
                </Paragraph>
                <Paragraph style={{ fontSize: '1.25em', textAlign: 'justify', marginBottom: 50 }}>
                    Four phases of implementation has been planned to reach this goal.
                </Paragraph>
                <Timeline mode="alternate">
                    <Timeline.Item>
                        <strong>Phase I: Training and Development (May 2015 to July 2017) - Completed</strong><br/>
                        Using digital communication mediums, local and international experts have trained local FabLanka members to build required core capabilities for establishing and maintaining a fabrication lab. These core capabilities include Computer Aided Design (CAD) using open source software and building and operating 3D printers for fabrication.
                    </Timeline.Item>
                    <Timeline.Item>
                        <strong>Phase II: Establishment of a Pilot FabLab (January 2017 to December 2017) – Completed</strong><br/>
                        A pilot fab lab was established in Makandura and this FabLab will join the global network of fab labs by the end of 2017. FabLab Makndura allows anyone in the local community to access its resources for education, idea generation and prototyping of any products. FabLab Makandura is equipped with digital fabrication tools such as CNC machines, laser cutters, 3D printers and many other tools and devices.
                    </Timeline.Item>
                    <Timeline.Item color='green'>
                        <strong>Phase III: Conduct formal education courses on digital fabrication and new Product development (July 2017 – December 2018) – In Progress</strong><br />
                        A pilot fab lab was established in Makandura and this FabLab will join the global network of fab labs by the end of 2017. FabLab Makndura allows anyone in the local community to access its resources for education, idea generation and prototyping of any products. FabLab Makandura is equipped with digital fabrication tools such as CNC machines, laser cutters, 3D printers and many other tools and devices.
                    </Timeline.Item>
                    <Timeline.Item>
                        <strong>Phase IV: Establish a Network of Fab Labs (January 2019 onwards) – Planned</strong><br />
                        In corporation with local communities, a network of fab labs will be developed at strategic locations throughout the country. Local projects will be identified to integrate fab labs into their respective communities. Fab labs will serve as both educational and manufacturing centers for the community. The cooperative network of fab labs will strengthen each individual fab lab with knowledge and resources.
                    </Timeline.Item>
                </Timeline>
            </div>
        );
    }
}