import React from 'react';

export class Contact extends React.Component {
    render() {
        return (
            <React.Fragment>
                Contact FabLanka Team @ <a href="mailto:info@fablanka.org">info@fablanka.org</a>
            </React.Fragment>
        );
    }
}