import React from 'react';
import { List } from 'antd';

export class Education extends React.Component {
    render() {
        return (
            <React.Fragment>
                <p style={{marginTop: 50}}>
                    FabLanka aims to deliver free and structured courses for community education and commercial purposes. The courses will include training on CAD software, use and troubleshooting of fabrication machinery, and business development. An internationally recognized certificate program will be offered for motivated individuals to learn new skills that will help them in the job market. In addition, there will be unstructured programs such as tutorials, trainings, and workshops on a need basis. With partnerships between local colleges and businesses, FabLanka can initiate local economic development projects. FabLanka will serve as a resource hub for local communities, schools, and universities to access knowledge and development of technology.  
                </p>
                <List
                    size="small"
                    header={<h3>Currently, FabLanka is offering a 6 months long Education program. The objectives of this Fab Education program are:</h3>}
                    dataSource={[
                        "Dispersion of advanced technologies to rural communities",
                        "Tap the potential of untapped human workforce and motivate them to believe and innovate by providing access to advanced technologies",
                        "Develop core capabilities to product development and management"
                    ]}
                    split={false}
                    renderItem={item => <List.Item>&#8226; {item}</List.Item>}
                />
                <List
                    size="small"
                    header={<h3>The participants who will complete this 6 month Fab Education program will gain expertise in the following areas:</h3>}
                    dataSource={[
                        "3D Modeling",
                        "3D Printing",
                        "CNC Programming and machining",
                        "Laser Cutting/Engraving",
                        "Creating Arts and Crafts using software and digitally controlled machines",
                        "Building and programming automated control devices using Arduino controller"
                    ]}
                    split={false}
                    renderItem={item => <List.Item>&#8226; {item}</List.Item>}
                />
                <List
                    size="small"
                    header={<h3>FabLanka Philosophy in conducting the training program is:</h3>}
                    dataSource={[
                        "Safety is the number one priority",
                        "Trying any wild idea and making mistakes or failures are okay but not trying anything is not okay"
                    ]}
                    split={false}
                    renderItem={item => <List.Item>&#8226; {item}</List.Item>}
                />
                <p style={{marginBottom: 50}}>
                    In the near future, FabLanka plans to start collaborative workshops to develop new products and services that are tailored to provide solutions for pressing issues like drinking water, energy, transportation etc. These workshops will be conducted with the help of professionals locally and internationally.
                </p>
                <strong>If you are interested in participating in the Fab Education Program, please contact us at info@fablanka.org</strong>
            </React.Fragment>
        );
    }
}