import React from 'react';
import { withRouter } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import './layout.css';

const { Header, Content, Footer } = Layout;

class LayoutClass extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <Layout className="layout">
                    <Header>
                        <div className="logo">
                            <img src='/images/FabLanka_Banner.png' width={300} onClick={() => this.props.history.push('/')} />    
                        </div>
                        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[this.props.location.pathname]}>
                            <Menu.Item key="/" onClick={() => this.props.history.push('/')}>Home</Menu.Item>
                            <Menu.Item key="/about" onClick={() => this.props.history.push('/about')}>About Us</Menu.Item>
                            <Menu.Item key="/plan" onClick={() => this.props.history.push('/plan')}>Our Plan</Menu.Item>
                            <Menu.Item key="/technology" onClick={() => this.props.history.push('/technology')}>Technology</Menu.Item>
                            <Menu.Item key="/education" onClick={() => this.props.history.push('/education')}>Fab Education</Menu.Item>
                            <Menu.Item key="/team" onClick={() => this.props.history.push('/team')}>Our Team</Menu.Item>
                            <Menu.Item key="/fablabs" onClick={() => this.props.history.push('/fablabs')}>FabLabs</Menu.Item>
                            <Menu.Item key="/contact" onClick={() => this.props.history.push('/contact')}>Contact Us</Menu.Item>
                        </Menu>
                    </Header>
                    <Content style={{ padding: '25px 100px 0px 100px', backgroundColor: 'white', paddingBottom: 100 }}>
                        {this.props.children}
                    </Content>
                    <Footer style={{ textAlign: 'center', position: 'fixed', bottom: 0, left: 0, right: 0 }}>
                        FabLanka ©2020 Created by @fablanka
                        <a href="https://ultra20.lankahost.net:2096/" target="_blank" style={{ textAlign: 'right', position: 'fixed', bottom: 25, left: 0, right: 50 }}>Check Mail</a>
                    </Footer>
                </Layout>
            </React.Fragment>
        );
    }
}

export const LayoutComponent = withRouter(LayoutClass);