import React from 'react';
import { Typography, Row, Col, Card } from 'antd';
import './aboutpage.css';

const { Title, Paragraph } = Typography;

export class About extends React.Component {
    render() {
        return (
            <div style={{marginTop: 50}}>
                <Row gutter={16}>
                    <Col span={15}>
                        <Title level={3}>A Globally Connected Sri Lankan Social Enterprise</Title>
                        <Paragraph style={{fontSize: '1.25em', textAlign: 'justify'}}>
                            FabLanka was publically launched in May 2015 to facilitate and nurture the growth of innovative fabrication methods in Sri Lanka. FabLanka Foundation (GTE) LTD was incorporated as a not-for-profit social enterprise in April 2016 under Companies Act , No 7 of 2007 of Sri Lanka.
                        </Paragraph>
                        <Paragraph style={{fontSize: '1.25em', textAlign: 'justify'}}>
                            A cooperative between professionals in Sri Lanka and around the world, FabLanka aims to bring about educational and economic changes while minimizing environmental impact to Sri Lanka through the digital fabrication revolution.
                        </Paragraph>
                        <Paragraph style={{fontSize: '1.25em', textAlign: 'justify'}}>
                            The goal of this cooperative is to develop fabrication labs at strategic locations throughout the island. Over time, these fabrication labs will connect to the larger global digital fabrication networks and organizations (fabfoundation.org) allowing local communities to innovate and invent new technologies and share knowledge that will positively transform the lives of peopl
                        </Paragraph>
                        <Paragraph style={{fontSize: '1.25em', textAlign: 'justify'}}>
                            FabLanka’s mission is to enable this growth to occur through laying the foundations for fabrication labs, providing access to knowledge and tools, and creating opportunities for communities to get involved.
                        </Paragraph>
                    </Col>
                    <Col span={9}>
                        <Card title="Our Vision" style={{ width: '100%', marginTop: 50, borderColor: '#3097D1' }}>
                            <p style={{fontSize: '1.2em'}}>Establish a technologically advanced and globally connected makers' society in Sri Lanka.</p>
                        </Card>
                        <Card title="Our Mission" style={{ width: '100%', marginTop: 25, borderColor: '#3097D1' }}>
                            <p style={{fontSize: '1.2em'}}>Provide opportunities to innovate and build almost anything that could improve the quality of life. Provide a platform of highest standard to exchange knowledge, ideas and expertise on existing and emerging Technologies.</p>
                        </Card>
                    </Col>
                </Row>

                <Title level={3} style={{marginTop: 25}}>Towards a Makers’ society through Community Outreach</Title>
                <Paragraph style={{fontSize: '1.25em', textAlign: 'justify'}}>
                    Developed as a community outreach project, FabLanka allows local communities at any socio-economic background to channel their creative energy towards technological innovation. Fabrication labs will serve as an educational apparatus by providing the knowledge and tools through which communities can innovate according to their needs. This will empower the youth and intern the community towards local economic development.
                </Paragraph>
                <Paragraph style={{fontSize: '1.25em', textAlign: 'justify'}}>
                    Communities have unique identities and needs. For digital fabrication methods such as additive manufacturing, unique necessities of each community become an advantage as opposed to the mass assembly line manufacturing methods of the past. In Sri Lanka, most cities and towns thrive on small businesses that are stifled by lack of technical innovation and financial burdens. Fabrication labs will serve small and medium scale enterprises (SME) by providing them with the necessary technology and infrastructure in the form of education, training, and product development capabilities that neither local governments nor businesses have the capacity to invest in. Innovative fabrication methods developed locally for local markets can help overcome the stagnation caused by global economic pressures and local economic underdevelopment.
                </Paragraph>

                <Title level={3} style={{marginTop: 25}}>Towards a Greener Economy that protects the environment</Title>
                <Paragraph style={{fontSize: '1.25em', textAlign: 'justify'}}>
                    The environmental degradation caused by modern economies is severe. Much of the raw materials and energy used for production is wasted in the manufacturing and transportation processes. Local manufacturing methods are needed to pave way for a greener economy. Developing countries have the advantage of not having to change the foundations of their economic structure to exploit the changes necessary for a sustainable economy. The needs of a sustainable economy can be met on a local level with innovative manufacturing methods. Fabrication labs can lead the way in identifying sustainable manufacturing practices for local economies to prosper.
                </Paragraph>
                <Paragraph style={{fontSize: '1.25em', textAlign: 'justify'}}>
                    FabLanka aims to bridge the gap between advancements in information technology and lack of advancement in manufacturing techniques in Sri Lanka. In doing so, the goal is to solve multifaceted problems through education, innovation and building sustainable local communities that are globally connected.
                </Paragraph>
            </div>
        );
    }
}