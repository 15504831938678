import React from 'react';

export class Blog extends React.Component {
    render() {
        return (
            <React.Fragment>
                Blog
            </React.Fragment>
        );
    }
}