import React from 'react';
import { Typography, Card, Layout, List, Row, Divider } from 'antd';
import Gallery from 'react-grid-gallery';
import './technology.css';

const { Title, Paragraph } = Typography;
const { Header, Content, Footer } = Layout;

export class Technology extends React.Component {
    render() {
        return (
            <React.Fragment>
                <h2 style={{ marginTop: 25, fontSize: '1.4em', textAlign: 'center', color: '#0244a1' }}>FabLanka uses the most advanced technologies available today for teaching, work force development and product development</h2>
                <List
                    size="small"
                    header={<h3>FabLanka has the access to the following open source software:</h3>}
                    dataSource={openSourceSoftware}
                    split={false}
                    renderItem={item => <List.Item>&#8226; {item}</List.Item>}
                />
                <h3 style={{ marginTop: 25, marginBottom: 25, fontSize: '1.4em' }}>
                    Following are the advanced technology equipment’s and devices that are available in the FabLab Makandura which is the first Fablab started by FabLanka Foundation in May 2017
                </h3>
                <h3 style={{color: '#18008b', marginTop: 25}}>Makandura FabLab – Digital Manufacturing Devices</h3>
                <Gallery images={FabLabImages} enableImageSelection={false}/>
                <Divider style={{marginBottom: 50}} />
                <h3 style={{color: '#18008b'}}>Educational Project Equipment/Kits</h3>
                <Gallery images={EquipmentImages} enableImageSelection={false}/>
            </React.Fragment>
        );
    }
}

const openSourceSoftware = [
    "Operating System – Ubuntu Linux",
    "Onshape and FreeCAD – 3D Parametric Design",
    "OpenPLM – Product Lifecycle Management",
    "Blender – 3D Modeling, Rendering and Animation",
    "Moodle – Learning Management System for course creators and administrators",
    "Dia – Tool equivalent to MS Visio",
    "Alfresco – Records/Document Management System",
    "GIMP – Image/Photo editing",
    "Libre Office – Word processing and spreadsheet",
    "Project.net – Project Management Software",
    "GNU R – Statistical Analysis",
    "Wordpress – Blog engine",
    "MySQL – Database"
];

const FabLabImages = [
    {
        src: "/images/equipment/makandura/flashforge3dprinter.jpg",
        thumbnail: "/images/equipment/makandura/flashforge3dprinter.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "Flash Forge 3D Printer"
    },
    {
        src: "/images/equipment/makandura/robo3d.jpg",
        thumbnail: "/images/equipment/makandura/robo3d.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "Robo3D Printer"
    },
    {
        src: "/images/equipment/makandura/reprapprusa.png",
        thumbnail: "/images/equipment/makandura/reprapprusa.png",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "RepRap Prusa i3 3D Printer"
    },
    {
        src: "/images/equipment/makandura/ibmthinkpad.png",
        thumbnail: "/images/equipment/makandura/ibmthinkpad.png",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "IBM Thinkpad Workstation"
    },
    {
        src: "/images/equipment/makandura/cardboardcutter.png",
        thumbnail: "/images/equipment/makandura/cardboardcutter.png",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "Arts&Crafts; Drafter & Paper/Cardboard Cutter"
    },
    {
        src: "/images/equipment/makandura/cncrouter.png",
        thumbnail: "/images/equipment/makandura/cncrouter.png",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "CNC Wood/Vinyl/makandura/Fiberglass Router"
    },
    {
        src: "/images/equipment/makandura/lasercutter.png",
        thumbnail: "/images/equipment/makandura/lasercutter.png",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "Laser Cutter/Engraver – Cuts Wood, Vinyl and Fiberglass"
    }
];

const EquipmentImages = [
    {
        src: "/images/equipment/circuit.png",
        thumbnail: "/images/equipment/circuit.png",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "Microcontroller and Circuit Kit"
    },
    {
        src: "/images/equipment/circuit2.png",
        thumbnail: "/images/equipment/circuit2.png",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "Microcontroller and Circuit Kit"
    },
    {
        src: "/images/equipment/ironon.jpg",
        thumbnail: "/images/equipment/ironon.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "Iron-On for Cardboard Cutter"
    },
    {
        src: "/images/equipment/vinyl.jpg",
        thumbnail: "/images/equipment/vinyl.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "Vinyl for Cardboard Cutter"
    },
    {
        src: "/images/equipment/solder.jpg",
        thumbnail: "/images/equipment/solder.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "Solder Station"
    },
    {
        src: "/images/equipment/cables.jpg",
        thumbnail: "/images/equipment/cables.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "Circuit Cables"
    },
    {
        src: "/images/equipment/connectors.jpg",
        thumbnail: "/images/equipment/connectors.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "Circuit Connectors"
    },
    {
        src: "/images/equipment/powersupply.jpg",
        thumbnail: "/images/equipment/powersupply.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "Transformer"
    },
    {
        src: "/images/equipment/drill.jpg",
        thumbnail: "/images/equipment/drill.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "Power Drill"
    },
    {
        src: "/images/equipment/gluegun.jpg",
        thumbnail: "/images/equipment/gluegun.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "Glue Gun"
    },
    {
        src: "/images/equipment/tools.jpg",
        thumbnail: "/images/equipment/tools.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "Hand Tools"
    }
];