import React from 'react';
import { LayoutComponent } from './Components/Layout';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { Home } from './Components/Home';
import { FabLabs } from './Components/FabLabs';
import { About } from './Components/About';
import { createBrowserHistory } from 'history';

import 'antd/dist/antd.css';
import './App.css';
import { Plan } from './Components/Plan';
import { Technology } from './Components/Technology';
import { Education } from './Components/Education';
import { Team } from './Components/Team';
import { Contact } from './Components/Contact';
import { Blog } from './Components/Blog';

const history = createBrowserHistory();

function App() {
  return (
    <Router history={history}>
      <LayoutComponent>
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/plan">
            <Plan />
          </Route>
          <Route path="/technology">
            <Technology />
          </Route>
          <Route path="/education">
            <Education />
          </Route>
          <Route path="/fablabs">
            <FabLabs />
          </Route>
          <Route path="/team">
            <Team />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </LayoutComponent>
    </Router>
  );
}

export default App;