import React from 'react';
import Gallery from 'react-grid-gallery';

export class FabLabs extends React.Component {
    render() {
        return (
            <React.Fragment>
                <h4 style={{ marginTop: 50 }}>
                    FabLab Maknadura was started on May 27 th , 2017 and is the first Fabrication Laboratory established by
                    FabLanka and also the first of its kind in Sri Lanka. FabLab Makandura is located at the Makandura Public
                    Library complex, in Makandura township of Kurunegala district.
                </h4>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9412.063623209619!2d79.97478939017489!3d7.321818160180128!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xaa493fc1c1d13b3e!2sFabLab+Makandura!5e0!3m2!1sen!2sus!4v1503251089516"
                    width="100%" height="450" frameBorder="0" style={{ border: '0' }} allowFullScreen>
                </iframe>
                <p style={{ marginTop: 25 }}>
                    The FabLab Maknadura is equipped with the following digital manufacturing tools and learning
                    resources:
                    </p>
                <p style={{ marginTop: 10, marginBottom: 25 }}>
                    A 6 month long free education program on digital design and fabrication is underway at the
                    FabLab Makandura. If you are interested in this free education program, please contact us at
                        <span style={{ color: 'blue' }}> info@fablanka.org</span>.
                    </p>
                <Gallery images={IMAGES} enableImageSelection={false} />
            </React.Fragment>
        );
    }
}

const IMAGES = [
    {
        src: "/images/makandura/pic3.jpg",
        thumbnail: "/images/makandura/pic3.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 174,
        caption: "Makandura FabLab"
    },
    {
        src: "/images/makandura/pic1.jpg",
        thumbnail: "/images/makandura/pic1.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "Makandura FabLab Opening"
    },
    {
        src: "/images/makandura/pic2.jpg",
        thumbnail: "/images/makandura/pic2.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "Makandura FabLab Opening"
    },
    {
        src: "/images/makandura/pic4.jpg",
        thumbnail: "/images/makandura/pic4.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "Some of the students from Makandura FabLab CAD Course"
    },
    {
        src: "/images/makandura/pic5.jpg",
        thumbnail: "/images/makandura/pic5.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "ROBO 3D 3D Printer"
    },
    {
        src: "/images/makandura/pic6.jpg",
        thumbnail: "/images/makandura/pic6.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "Prusa i3 3D Printer"
    },
    {
        src: "/images/makandura/pic7.jpg",
        thumbnail: "/images/makandura/pic7.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "IBM Thinkpad Workstations"
    },
    {
        src: "/images/makandura/pic8.png",
        thumbnail: "/images/makandura/pic8.png",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "CNC Wood/Vinyl/Fiberglass Router"
    },
];