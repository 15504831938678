import React from 'react';
import { Card, Row, Col } from 'antd';

const { Meta } = Card;

export class Team extends React.Component {
    render() {
        return (
            <React.Fragment>
                <h3 style={{marginTop: 50, marginBottom: 50}}>FabLanka is fortunate to have the following dedicated team of volunteers</h3>
                <Row gutter={16} style={{marginBottom: 25}}>
                    <Col span={6}>
                        <Card
                            hoverable
                            style={{ textAlign: 'center' }}
                            cover={<img height={300} alt="Greshan Fernando" src="/images/team/GreshanFernando.png" />}
                        >
                            Director of Fablanka Foundation
                            <Meta title="Greshan Fernando" description={<a href="mailto:greshan@fablanka.org">greshan@fablanka.org</a>} />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card
                            hoverable
                            style={{ textAlign: 'center' }}
                            cover={<img height={300} alt="Chaminda Hettiarachchi" src="/images/team/ChamindaHettiarachchi.png" />}
                        >
                            Director of Fablanka Foundation
                            <Meta title="Chaminda Hettiarachchi" description={<a href="mailto:chaminda@fablanka.org">chaminda@fablanka.org</a>} />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card
                            hoverable
                            style={{ textAlign: 'center' }}
                            cover={<img height={300} alt="Priyantha Palapathwala" src="/images/team/PriyanthaPalapathwala.png" />}
                        >
                            Director of Fablanka Foundation
                            <Meta title="Priyantha Palapathwala" description={<a href="mailto:priyantha@fablanka.org">priyantha@fablanka.org</a>} />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card
                            hoverable
                            style={{ textAlign: 'center' }}
                            cover={<img height={300} alt="Buddhika Jayasena" src="/images/team/BuddhikaJayasena.jpg" />}
                        >
                            Director of Fablanka Foundation
                            <Meta title="Buddhika Jayasena" description={<a href="mailto:buddhika@fablanka.org">buddhika@fablanka.org</a>} />
                        </Card>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        <Card
                            hoverable
                            style={{ textAlign: 'center' }}
                            cover={<img height={300} alt="Gayan Srinath" src="/images/team/GayanSrinath.png" />}
                        >
                            Founding Member
                            <Meta title="Gayan Srinath" description={<a href="mailto:gayan@fablanka.org">gayan@fablanka.org</a>} />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card
                            hoverable
                            style={{ textAlign: 'center' }}
                            cover={<img height={300} alt="Pubudu Senaratne" src="/images/team/PubuduSenaratne.jpg" />}
                        >
                            Founding Member
                            <Meta title="Pubudu Senaratne" description={<a href="mailto:pubudu@fablanka.org">pubudu@fablanka.org</a>} />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card
                            hoverable
                            style={{ textAlign: 'center' }}
                            cover={<img height={300} alt="Thivanka Samaranayake" src="/images/team/ThivankaSamaranayake.png" />}
                        >
                            Founding Member
                            <Meta title="Thivanka Samaranayake" description={<a href="mailto:thivanka@fablanka.org">thivanka@fablanka.org</a>} />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card
                            hoverable
                            style={{ textAlign: 'center' }}
                            cover={<img height={300} alt="Sandun Ranaweera" src="/images/team/SandunRanaweera.png" />}
                        >
                            Founding Member
                            <Meta title="Sandun Ranaweera" description={<a href="mailto:sandun@fablanka.org">sandun@fablanka.org</a>} />
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}